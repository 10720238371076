export const TileTextStyles = {
    baseStyle: {},
    sizes: {
        sm: {
            fontSize: "12px",
        },
        md: {
            fontSize: "14px",
        },
        lg: {
            fontSize: "16px",
        },
    },
    variants: {
        primary: {
            color: "prussianBlue",
            lineHeight: 2,
            paddingBottom: "24px",
        },
        secondary: {
            color: "prussianBlue",
            lineHeight: 2,
        },
    },
    defaultProps: {
        variant: "primary",
    },
};

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { auth } from '../firebase';

const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_BFF_ENDPOINT,
});

const authLink = setContext(async (_, { headers }) => {
    const token = `Bearer ${await auth?.currentUser?.getIdToken() || localStorage.getItem('jwt')}`;

    return {
        headers: {
            ...headers,
            authorization: token,
        }
    }
});


const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export default apolloClient;
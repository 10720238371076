import { extendTheme } from "@chakra-ui/react";

// Colors
import { Colors as colors } from "./colors";

// Base Component Styles
import { AccordionStyles as Accordion } from "./components/base/AccordionStyles";
import { BoxStyles as Box } from "./components/base/BoxStyles";
import { ButtonStyles as Button } from "./components/base/ButtonStyles";
import { ButtonGroupStyles as ButtonGroup } from "./components/base/ButtonGroupStyles";
import { HeadingStyles as Heading } from "./components/base/HeadingStyles";
import { ContainerStyles as Container } from "./components/base/ContainerStyles";
import { LinkStyles as Link } from "./components/base/LinkStyles";
import { TextStyles as Text } from "./components/base/TextStyles";
import { UnorderedListStyles as UnorderedList } from "./components/base/UnorderedListStyles";

// Global Component Styles
import { FooterStyles as Footer } from "./components/containers/footer/FooterStyles";

// Common Component Styles
import { BackgroundGradientStyles as BackgroundGradient } from "./components/common/BackgroundGradientStyles";
import { BreadcrumbStyles as Breadcrumb } from "./components/common/BreadcrumbStyles";
import { CardStyles as Card } from "./components/cards/CardStyles";
import { PlayButtonStyles as PlayButton } from "./components/common/PlayButtonStyles";

// Tile Styles
import { TileHeaderStyles as TileHeader } from "./components/TileHeaderStyles";
import { TileImageStyles as TileImage } from "./components/TileImageStyles";
import { TileListStyles as TileList } from "./components/TileListStyles";
import { TileSubheaderStyles as TileSubheader } from "./components/TileSubheaderStyles";
import { TileTextStyles as TileText } from "./components/TileTextStyles";

// Hero Text Styles
import { HeroHeaderStyles as HeroHeader } from "./components/hero/HeroHeaderStyles";
import { HeroTextStyles as HeroText } from "./components/hero/HeroTextStyles";

// Card Text Styles
import { CardHeaderStyles as CardHeader } from "./components/cards/CardHeaderStyles";
import { CardIconStyles as CardIcon } from "./components/cards/CardIconStyles";
import { CardTextStyles as CardText } from "./components/cards/CardTextStyles";

// Package Text Styles
import { DetailsHeaderStyles as PackageDetailsHeader } from "./components/common/packages/DetailsHeaderStyles";
import { DetailsTextStyles as PackageDetailsText } from "./components/common/packages/DetailsTextStyles";

// Welcome Quiz Styles
import { QuizButtonStyles as QuizButton } from "./components/containers/welcomequiz/QuizButtonStyles";

// Miscellaneous Styles
// import { CardStyles as Card } from "./components/common/miscellaneous/CardStyles";
import { HeroStyles as Hero } from "./components/common/miscellaneous/HeroStyles";
import { CardOverlayStyles as CardOverlay } from "./components/common/miscellaneous/overlays/CardOverlayStyles";
import { HeroOverlayStyles as HeroOverlay } from "./components/common/miscellaneous/overlays/HeroOverlayStyles";

export const vacayouTheme = extendTheme({
    colors,
    shadows: {
        primary:
            "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    },
    components: {
        Accordion,
        BackgroundGradient,
        Box,
        Breadcrumb,
        Button,
        ButtonGroup,
        Card,
        CardHeader,
        CardIcon,
        CardOverlay,
        CardText,
        Container,
        Footer,
        Heading,
        Hero,
        HeroHeader,
        HeroOverlay,
        HeroText,
        Link,
        PackageDetailsHeader,
        PackageDetailsText,
        PlayButton,
        QuizButton,
        Text,
        TileHeader,
        TileImage,
        TileList,
        TileSubheader,
        TileText,
        UnorderedList,
    },
});

import { darken } from "@chakra-ui/theme-tools";

// This is for the Buttons at the bottom of the quiz page
export const QuizButtonStyles = {
    baseStyle: {
        borderRadius: "3px",
        textTransform: "uppercase",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
    },
    // This is the size of the buttons in the welcome quiz
    sizes: {
        base: {
            padding: "16px 24px",
            fontSize: "14px",
            height: "auto",
        },
        sm: {
            padding: "16px 24px",
            fontSize: "14px",
            height: "auto",
        },
        md: {
            padding: "8px 10px",
            fontSize: "12px",
            height: "auto",
        },
        lg: {
            padding: "12px 20px",
            fontSize: "12px",
            height: "auto",
        },
        xl: {
            padding: "16px 24px",
            fontSize: "14px",
        },
    },
    variants: {
        primary: {
            color: "white",
            backgroundColor: "gold.500",
            _hover: {
                color: "white",
                backgroundColor: darken("gold.500", 10),
                transform: "scale(1.02)",
            },
        },
    },
    defaultProps: {
        variant: "primary",
    },
};

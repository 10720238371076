export const TileSubheaderStyles = {
    baseStyle: {
        fontWeight: "normal",
        fontStyle: "italic",
    },
    sizes: {
        sm: {
            fontSize: "10px",
        },
        md: {
            fontSize: "12px",
        },
        lg: {
            fontSize: "14px",
        },
    },
    variants: {
        primary: {
            color: "#FFFFFF",
        },
        secondary: {
            color: "gold.500",
        },
    },
    defaultProps: {
        variant: "primary",
    },
};

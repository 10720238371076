export const CardHeaderStyles = {
    baseStyle: {
        color: "white",
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: 900,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "left",
        textTransform: "uppercase",
    },
    sizes: {},
    variants: {
        package: {
            fontSize: "15px",
            textAlign: "center",
        },
        perk: {},
        video: {},
        wellness: {
            color: "charcoal",
        },
        quizOption: {
            fontWeight: 300,
            letterSpacing: "3px",
            textAlign: "center",
            color: "#fffef7",
        },
    },
    defaultProps: {},
};

import React, { useEffect, useState } from "react";
import ContentPageTemplate from "../components/templates/ContentPageTemplate";
import { UserAuthProvider } from "@/services/hooks/useAuth";
import { ApiProvider } from "@/services/hooks/useApi";
import { PopupProvider } from "@/services/hooks/usePopup";
import "../styles/globals.scss";
import TagManager from "react-gtm-module";
import { ChakraProvider } from "@chakra-ui/react";
import { vacayouTheme } from "../styles/vacayouTheme";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "@/services/gql/apollo";
import { UserContext } from "../contexts/userContext";

function MyApp({ Component, pageProps }) {
    const defaultUserState = {
        userType: "VISITOR",
    };
    const defaultB2bState = {
        organization: "",
        logo: { desktop: "", mobile: "" },
    };

    const [userState, setUserState] = useState(defaultUserState);

    const [b2bState, setB2bState] = useState(defaultB2bState);

    // Google Tag Manager
    useEffect(() => {
        TagManager.initialize({ gtmId: "GTM-P3BS8LL" });

        function checkUserData() {
            const subscriptionType = localStorage.getItem("subscriptionType");
            const sponsorOrganization = localStorage.getItem("orgAlias");
            !!subscriptionType
                ? setUserState({ ...userState, userType: subscriptionType })
                : setUserState(defaultUserState);
            !!sponsorOrganization &&
                setB2bState({
                    ...b2bState,
                    organization: sponsorOrganization,
                    logo: {
                        desktop: `https://s3cdn.vacayou.com/sponsors/${sponsorOrganization}-desktop.png`,
                        mobile: `https://s3cdn.vacayou.com/sponsors/${sponsorOrganization}-mobile.png`,
                    },
                });
        }

        checkUserData();

        window.addEventListener("storage", checkUserData);

        return () => {
            window.removeEventListener("storage", checkUserData);
        };
    }, []);

    return (
        <ApolloProvider client={apolloClient}>
            <ApiProvider>
                <PopupProvider>
                    <UserContext.Provider value={{ userState, setUserState, b2bState, setB2bState }}>
                        <UserAuthProvider>
                            <ChakraProvider theme={vacayouTheme}>
                                <ContentPageTemplate>
                                    <Component {...pageProps} />
                                </ContentPageTemplate>
                            </ChakraProvider>
                        </UserAuthProvider>
                    </UserContext.Provider>
                </PopupProvider>
            </ApiProvider>
        </ApolloProvider>
    );
}

export default MyApp;

export const DetailsTextStyles = {
    baseStyle: {
        fontFamily: "Lato",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.33,
        letterSpacing: "normal",
        textAlign: "left",
    },
    sizes: {},
    variants: {
        primary: {
            fontSize: "15px",
            color: "#354b54",
        },
        secondary: {
            fontSize: "15px",
            color: "#9d9d9d",
        },
    },
    defaultProps: {
        variant: "primary",
    },
};

export const PlayButtonStyles = {
    parts: ["Box", "Image"],
    baseStyle: {
        box: {
            padding: "inherit",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 100,
        },
        image: {
            height: { base: "40px", md: "60px", lg: "100px" },
            width: { base: "40px", md: "60px", lg: "100px" },
        },
    },
    sizes: {},
    variants: {
        // TODO create variants for small and large play buttons
    },
    defaultProps: {},
};

export const DetailsHeaderStyles = {
    baseStyle: {
        fontFamily: "Lato",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1,
        letterSpacing: "normal",
        textAlign: "left",
    },
    sizes: {},
    variants: {
        primary: {
            color: "#444",
            fontSize: "50px",
            fontWeight: 300,
            lineHeight: "normal",
        },
        secondary: {
            color: "#354b54",
            fontSize: "20px",
            textTransform: "uppercase",
        },
    },
    defaultProps: {
        variant: "primary",
    },
};

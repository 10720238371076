export const BackgroundGradientStyles = {
    baseStyle: {
        position: "absolute",
        height: "100%",
        width: "100%",
        mixBlendMode: "multiply",
    },
    variants: {
        card: {
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0), #858266 75%, #000)",
        },
        hero: {
            background: "linear-gradient(to bottom, #524f38, #858266 21%, rgba(255, 255, 255, 0))",
        },
    },
};

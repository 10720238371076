export const AccordionStyles = {
    parts: ["button", "panel"],
    baseStyle: {
        button: {
            color: "prussianBlue",
            fontWeight: "bold",
            _hover: { background: "platinum.500" },
        },
        panel: {
            color: "prussianBlue",
        },
    },
};

import Head from "next/head";
import { Footer, Navigation } from "../containers";
import { useAuth } from "@/services/hooks/useAuth";
import { auth } from "@/services/firebase";
import React, { useEffect } from "react";
import { PopupStack } from "../common/Popup/Popup";
import { Box } from "@chakra-ui/react";

const ContentPageTemplate = ({ children }): JSX.Element => {
    // Why are we doing this here??
    // TODO move auth initialization to a more global location (DEV-1464)
    const { handleAuthChange } = useAuth();

    useEffect(() => {
        auth.onAuthStateChanged(handleAuthChange);
    }, []);

    return (
        <React.Fragment>
            <Head>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
                    rel="stylesheet"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
                    rel="stylesheet"
                />
            </Head>
            <Box display="flex" flexDirection="column" minHeight="100vh" justifyContent="space-between">
                <Navigation />
                <Box as="main" display="flex" flexDirection="column" flexGrow={1} minHeight="100%">
                    {children}
                </Box>
                <Footer />
            </Box>
            <PopupStack />
        </React.Fragment>
    );
};

export default ContentPageTemplate;

export const HeroOverlayStyles = {
    baseStyle: {
        position: "absolute",
        height: "100%",
        width: "100%",
        mixBlendMode: "multiply",
        opacity: 0.5,
        backgroundBlendMode: "multiply",
        background: "linear-gradient(to bottom, #858266, #858266)",
    },
};

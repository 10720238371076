export const ButtonStyles = {
    baseStyle: {
        fontFamily: "Lato",
        textTransform: "uppercase",
    },
    sizes: {
        md: {
            fontSize: "14px",
        },
    },
    variants: {},
    defaultProps: {},
};

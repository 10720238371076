export const BreadcrumbStyles = {
    baseStyle: {
        background: "sand",
        fontWeight: "medium",
        textTransform: "uppercase",
    },
    sizes: {},
    variants: {
        primary: {
            color: "gold.500",
        },
    },
    defaultProps: {
        variant: "primary",
    },
};

export const ContainerStyles = {
    baseStyle: {
        height: "100%",
        width: "100%",
        position: "relative",
    },
    sizes: {},
    variants: {
        hero: {
            paddingTop: { base: "16px" },
            paddingBottom: { base: "16px" },
        },
    },
    defaultProps: {},
};

export const Colors = {
    prussianBlue: "#10365c",
    yaleBlue: "#234c79",
    cadetBlue: "#62a8ac",
    middleBlueGreen: "#a1d2ce",
    charcoal: "#354b54",
    gunmetal: "#0e2b39",
    sand: "#fffef7",
    gold: {
        50: "#f5f5e7",
        100: "#dfddcf",
        200: "#c8c6b4",
        300: "#b2af99",
        400: "#9b987d",
        500: "#858266",
        600: "#65634c",
        700: "#484735",
        800: "#2b2a1d",
        900: "#0f0f00",
    },
    platinum: {
        50: "#f2f3f3",
        100: "#dadada",
        200: "#c0c0c0",
        300: "#a7a7a7",
        400: "#8e8e8e",
        500: "#e8e9e9",
        600: "#c0c0c0",
        700: "#404040",
        800: "#272727",
        900: "#0c0c0d",
    },
};

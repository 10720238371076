import { useEffect, useState } from "react";
import { usePopup } from "@/services/hooks/usePopup";
import commonStyles from "../../../styles/Common.module.scss";

interface PopupProps {
    type?: "SUCCESS" | "WARNING" | "ERROR";
    message: string;
    dismiss?: () => void;
    key?: string;
    dismissed?: boolean;
}

const TYPE_MAP: Record<PopupProps["type"], { iconClass: string; colorClass: string }> = {
    SUCCESS: {
        iconClass: "fas fa-check-circle",
        colorClass: commonStyles.popupSuccess,
    },
    ERROR: {
        iconClass: "fas fa-times-circle",
        colorClass: commonStyles.popupError,
    },
    WARNING: {
        iconClass: "fas fa-exclamation-circle",
        colorClass: commonStyles.popupWarning,
    },
};

function Popup({ type = "SUCCESS", message, dismiss = () => null }: PopupProps) {
    const currentType = TYPE_MAP[type];
    const [slideOutClass, setSlideOutClass] = useState("");
    const slideOut = () => {
        setSlideOutClass(commonStyles.fadeOut);
        setTimeout(() => dismiss(), 300);
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            slideOut();
        }, 5000);
        return () => {
            clearTimeout(timer);
        };
    }, []);
    return (
        <div
            className={[commonStyles.popup, currentType.colorClass, slideOutClass].join(" ")}
            onClick={() => slideOut()}
        >
            <div className="grid grid-cols-12 items-center ">
                <div className="col-span-2 sm:col-span-2 text-center">
                    <i className={[currentType.iconClass, "text-2xl sm:text-md"].join(" ")}></i>
                </div>
                <div className="col-span-9">
                    <p className="font-source font-bold">{message}</p>
                </div>
            </div>
        </div>
    );
}

function PopupStack() {
    const { popupList, remove } = usePopup();
    useEffect(() => {
        console.log(popupList);
    }, [popupList]);
    return (
        <div className={commonStyles.popupWrapper}>
            {popupList
                .filter((popup) => popup.dismissed == null)
                .map((popup, i) => (
                    <Popup key={popup.key} message={popup.message} type={popup.type} dismiss={() => remove(popup)} />
                ))}
        </div>
    );
}

export { Popup, PopupStack };

export type { PopupProps };

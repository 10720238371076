export const HeroTextStyles = {
    baseStyle: {
        color: "white",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        lineHeight: "normal",
    },
    variants: {
        primary: {
            fontFamily: "Source Sans Pro",
            fontSize: {
                base: "15px",
                sm: "15px",
                md: "16px",
                lg: "16px",
                xl: "18px",
            },
            fontWeight: "regular",
        },
        secondary: {
            fontFamily: "Lato",
            fontSize: {
                base: "16px",
                md: "16px",
                lg: "18px",
                xl: "20px",
            },
            fontWeight: 500,
        },
        subdued: {
            fontFamily: "Lato",
            fontSize: {
                base: "14px",
                md: "14px",
                lg: "14px",
                xl: "14px",
            },
            fontWeight: 500,
        },
        strong: {
            fontFamily: "Lato",
            fontSize: {
                base: "18px",
                md: "20px",
                lg: "24px",
                xl: "30px",
            },
            fontWeight: 500,
        },
    },
    defaultProps: {
        variant: "primary",
    },
};

export const CardTextStyles = {
    baseStyle: {
        color: "white",
        fontFamily: "Source Sans Pro",
        fontSize: "14px", // TODO move this
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "left",
    },
    sizes: {},
    variants: {
        package: {
            textAlign: "center",
        },
        perk: {},
        video: {},
        wellness: {
            color: "charcoal",
        },
    },
    defaultProps: {},
};

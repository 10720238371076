export const TileListStyles = {
    baseStyle: {
        color: "prussianBlue",
        lineHeight: 2,
        paddingBottom: "24px",
    },
    sizes: {
        sm: {
            fontSize: "12px",
        },
        md: {
            fontSize: "14px",
        },
        lg: {
            fontSize: "16px",
        },
    },
    variants: {},
    defaultProps: {},
};

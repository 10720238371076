export const HeroStyles = {
    parts: ["wrapper", "container"],
    baseStyle: {
        container: {
            paddingBottom: "16px",
            paddingTop: "16px",
        },
    },
    variants: {
        voyager: {
            wrapper: {
                marginTop: "132px",
            },
        },
        standard: {
            wrapper: {
                marginTop: "92px",
            },
        },
        visitor: {
            wrapper: {
                marginTop: "92px",
            },
        },
    },
};

export const HeroHeaderStyles = {
    baseStyle: {
        color: "white",
        fontFamily: "Lato",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        lineHeight: "normal",
        textAlign: "left",
        textTransform: "uppercase",
    },
    variants: {
        primary: {
            fontSize: {
                base: "36px",
                md: "42px",
                lg: "48px",
                xl: "60px",
            },
            fontWeight: 400,
            letterSpacing: "0.06px",
        },
        secondary: {
            fontSize: {
                base: "22px",
                md: "24px",
                lg: "26px",
                xl: "30px",
            },
            fontWeight: 600,
            letterSpacing: "9px",
        },
        subdued: {
            fontSize: {
                base: "14px",
                md: "16px",
                lg: "20px",
                xl: "22px",
            },
            fontWeight: 900,
        },
        strong: {
            fontSize: {
                base: "32px",
                sm: "40px",
                lg: "48px",
                xl: "50px",
            },
            fontWeight: 700,
            lineHeight: 0.9,
            textAlign: "center",
        },
        exploded: {
            fontSize: {
                base: "30px",
                md: "40px",
                lg: "60px",
                xl: "80px",
            },
            fontWeight: 300,
            letterSpacing: "16px",
            textAlign: "center",
        },
    },
    defaultProps: {
        variant: "secondary",
    },
};

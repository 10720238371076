export const TileImageStyles = {
    baseStyle: {
        width: "100%",
    },
    sizes: {},
    variants: {
        rounded: { borderRadius: "12px" },
        square: { borderRadius: 0 },
    },
    defaultProps: {
        variant: "square",
    },
};

export const CardStyles = {
    parts: ["card", "link"],
    baseStyle: {
        card: {
            borderRadius: "10px",
        },
        link: {
            borderRadius: "10px",
            _hover: {
                textDecoration: "none",
            },
        },
    },
    sizes: {},
    variants: {
        quizOptionActive: {
            _hover: {
                cursor: "pointer",
            },
        },
        quizOptionInactive: {
            _hover: {
                cursor: "pointer",
            },
        },
    },
    defaultProps: {},
};

export const CardOverlayStyles = {
    baseStyle: {
        position: "absolute",
        height: "100%",
        width: "100%",
        mixBlendMode: "multiply",
    },
    variants: {
        dashboard: {
            background: "linear-gradient(to bottom, transparent 66.7%, rgba(0, 0, 0, 0.6))",
        },
        perk: {
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0), #858266 75%, #000)",
        },
        trip: {
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0), #858266 75%, #000)",
        },
        video: {
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0), #858266 77%, #6a6a6a)",
        },
        altSlim: {
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0), #858266 81%, #4b4829);",
        },
        altSquare: {
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0) 3%, #858266 80%, rgba(69, 66, 38, 0.84))",
        },
        altTall: {
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 0) 21%, #858266 80%, #403e2e)",
        },
    },
};

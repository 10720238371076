export const LinkStyles = {
    baseStyle: {
        _hover: {
            textDecoration: "none",
        },
    },
    sizes: {},
    variants: {
        active: {
            textDecoration: "underline",
        },
    },
    defaultProps: {},
};

export const HeadingStyles = {
    baseStyle: {},
    sizes: {},
    variants: {
        primary: {
            fontFamily: "Lato",
            textTransform: "uppercase",
        },
        cursive: {
            fontFamily: "Brittany Signature",
            textTransform: "none",
        },
    },
    defaultProps: {
        variant: "primary",
    },
};

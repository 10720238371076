export const TileHeaderStyles = {
    parts: ["heading", "link", "icon"],
    baseStyle: {
        heading: {
            textTransform: "uppercase",
            fontWeight: "medium",
            letterSpacing: "0.1em",
        },
        link: {
            alignItems: "center",
            display: "inline-flex",
            fontWeight: "bold",
        },
        icon: {
            fontWeight: "inherit",
            fontSize: "inherit",
        },
    },
    sizes: {
        xs: {
            heading: {
                fontSize: "20px",
            },
            link: {
                fontSize: "14px",
            },
        },
        sm: {
            heading: {
                fontSize: "20px",
            },
            link: {
                fontSize: "14px",
            },
        },
        md: {
            heading: {
                fontSize: "22px",
            },
            link: {
                fontSize: "16px",
            },
        },
        lg: {
            heading: {
                fontSize: "24px",
            },
            link: {
                fontSize: "16px",
            },
        },
        xl: {
            heading: {
                fontSize: "24px",
            },
            link: {
                fontSize: "16px",
            },
        },
    },
    variants: {
        primary: {
            heading: {
                color: "#FFFFFF",
            },
            link: {
                color: "prussianBlue",
            },
        },
        secondary: {
            heading: {
                color: "gold.500",
            },
            link: {
                color: "gold.500",
            },
        },
    },
    defaultProps: {
        variant: "primary",
    },
};
